import Box from '../components/Box';
// import ElectricityOverlay from '../components/ElectricityOverlay';
import heroImage from '../images/heroImage.jpg';
import openCEMCloud from '../images/openCEMCloud.png';
import openCEMLogo from '../images/opencem_old.svg';

export default function SplashPage({ }) {
    return (
        <>
            <div style={{
                // background: `linear-gradient(217deg, #001c40, #002e6600), linear-gradient(127deg, #302457, rgba(0, 255, 0, 0)), linear-gradient(336deg, #001c40, rgba(0, 0, 255, 0))`,
                // background: `linear-gradient(251deg, rgb(0, 7, 99), rgba(0, 46, 102, 0)), linear-gradient(127deg, rgb(54 0 23), rgba(0, 255, 0, 0)), linear-gradient(356deg, rgb(0, 0, 0), rgba(0, 0, 255, 0))`
                background: `linear-gradient(206deg, rgb(0 8 111), rgba(0, 46, 102, 0)), linear-gradient(127deg, rgb(22 0 75), rgba(0, 255, 0, 0)), linear-gradient(356deg, rgb(0, 0, 0), rgba(0, 0, 255, 0))`
            }}
            >
                <div className='position-absolute end-0 '>
                    <a href='/share/a52084c7-049c-45f1-b2fc-faf09a62a810' className='btn btn-green'>Demo</a>
                    <a href='/accounts/login/' className='btn btn-link text-green m-3'>Sign in</a>
                </div>
                <div className='container-fluid'>
                    <div className='row px-4 align-items-center' style={{
                        height: '80vh',
                        // background: `linear-gradient(217deg, #190701, #002e6600 70.71%), linear-gradient(127deg, #302457, rgba(0, 255, 0, 0) 70.71%), linear-gradient(336deg, #001c40, rgba(0, 0, 255, 0) 70.71%)`
                    }}>
                        {/* <ElectricityOverlay /> */}
                        <div className='col-6'>
                            <img className='img-fluid w-50 float-end' src={openCEMLogo} />
                        </div>
                        <div className='col-6'>
                            <h1 className='text-light fw-bold mt-lg-4 mb-4 pb-3 border-light border-bottom'>Powerful, open-source electricity market modelling.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid bg-dark'>
                <div className="row p-lg-4">
                    <div className="col-12 border-light">
                        <div className="row">
                            <Box background='mid' width='full'>
                                <div className='text-light py-3'>

                                    <div className='row px-lg-5'>
                                        <div className='col-lg-6'>
                                            <p>openCEM is an open-source capacity-expansion modelling tool developed by <a href='https://itpau.com.au'>ITP Renewables</a>. It allows energy system planners, decision-makers, regulators, and project developers to undertake comprehensive modelling to provide insights on how to transition electricity systems to low-carbon power.</p>
                                            <p>Many modelling projects are completed using in-house or proprietary software. This means third parties are unable to scrutinise, review or validate the model, nor able to use it to run new scenarios or change input assumptions. Open-source software is published to allow others to both use and improve it, and conforms to principles of the scientific method. Moreover, open-source models facilitate knowledge sharing by building a community of interested researchers and practitioners.</p>
                                            <p>Specifically, openCEM allows you to ask questions such as:</p>
                                            <ul>
                                                <li className='pb-2'>What is the least-cost pathway to achieve specific future renewable energy or emission abatement targets? Specifically, when should existing generation capacity retire, and what, where, when and how much new capacity, storage and demand side management should be added?</li>
                                                <li className='pb-2'>What is the role of storage on the network? This question could examine large-scale storage, such as that associated with solar thermal plants; highly responsive pumped hydro plants; large-scale batteries providing network support; ‘behind the meter’ residential and commercial batteries and electric vehicle batteries.</li>
                                                <li className='pb-2'>How would improved energy efficiency and demand-side response assist in achieving higher renewable energy targets? The grid is evolving from one with inflexible demand and flexible (controllable) generation to one with less controllable (variable) generation and therefore will benefit from more flexible demand.</li>
                                                <li className='pb-2'>What is the role of generator flexibility in integrating higher shares of variable renewable generation?</li>
                                                <li className='pb-2'>Which emerging technologies potentially have the highest value to society via their ability to minimise cost and emissions and are therefore most deserving of public support to accelerate research, development and commercialisation.</li>
                                            </ul>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img className='img-fluid' src={heroImage} />
                                        </div>
                                        <h2 className='text-primary pt-4 border-mid border-bottom'>openCEM Cloud</h2>
                                        <hr className='mb-5 border-primary' />
                                        <div className='col-lg-6'>
                                            <img className='img-fluid' src={openCEMCloud} />
                                        </div>
                                        <div className='col-lg-6'>
                                            <p>openCEM Cloud is a fully-featured web application for using openCEM, without the need to install it locally. Users can upload scenarios, run them in the cloud, and visualise results.</p>
                                            <p>openCEM provides valuable new insights, allowing regulators and engineers to quickly examine the impact of:</p>
                                            <ul>
                                                <li className='pb-2'>A range of new policy or regulatory measures.</li>
                                                <li className='pb-2'>Economic factors such as technology costs or changes in fuel prices.</li>
                                                <li className='pb-2'>Technological change, including cost reduction and performance improvement of energy generation, transmission, storage, and energy efficiency.</li>
                                                <li className='pb-2'>Specific large energy projects.</li>
                                            </ul>
                                            <p>These inputs are editable through easy and accessible spreadsheets, and simulations can be managed and analysed using openCEM Cloud.</p>
                                        </div>
                                        <div className='col-12 pt-4'>
                                            <p>openCEM Cloud has been developed for locations in Australia and the Pacific, and can be quickly deployed to new jurisdictions once local inputs, including costs and available technologies, are determined. ITP has extensive experience collaborating with Pacific countries on data collection and modelling, and is well-placed to provide initial data for new deployments of openCEM.</p>
                                            <p>The Samoan government engaged ITP in 2023 through the Australian Department of Foreign Affairs and Trade (DFAT) to develop a version of openCEM for use by agencies in their long-term electricity sector planning, including the Office of the Regulator (OOTR) and the Electric Power Corporation (EPC). This included a fully featured, Samoa-specific set of input assumptions, a workbook and database. A web portal was built to facilitate the configuration, cloud-running and analysis of openCEM scenarios, with ITP providing extensive in-country training and materials to enable long-term electricity planning for the Samoan Office of the Regulator, and the Samoan Electrical Power Corporation.</p>
                                            <Box width='full' background='blue'>
                                                <p className='text-light fw-medium mb-0'>For more information on openCEM and openCEM Cloud, please contact Jose Zapata, principal of modelling and analytics at ITP Renewables:</p>
                                                <p className='mb-0'><a href='mailto:jzapata@itpau.com.au'>jzapata@itpau.com.au</a></p>
                                                <p className='mb-0'><a href='tel:+61 2 6257 3511'>+61 2 6257 3511</a></p>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};