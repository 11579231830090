import { NavLink } from "react-router-dom";
import LoadingOverlay from "./LoadingOverlay";

export default function Box({ children, background = "mid", border = "mid", width = 'md', height = "mh-100", padding = '4', updating = false, link, className }) {
    const widthStyles = {
        'sm': 'col-md-4 col-lg-3',
        'md': 'col-md-6 col-lg-4',
        'full': 'col-12',
    }

    return (
        <div className={`mb-4 ${widthStyles[width]} ${height}`}>
            <div
                className={`p-${padding} bg-${background} border border-${border} rounded-3 overflow-y-auto overflow-x-hidden mh-100 position-relative ${className || ''}`}
            >
                {link && <NavLink className='stretched-link' to={link}>
                    {({ isActive, isPending, isTransitioning }) => (
                        <>{isPending && <LoadingOverlay />}
                        </>
                    )}
                </NavLink>}
                {updating && <LoadingOverlay />}
                {children}
            </div>
        </div>
    )
}
